'use client';

import Image from 'next/image';
import { Text } from 'geist/components';
import type { LogoName } from 'geist/symbols';
import { Icon, Logo } from 'geist/symbols';
import { documentToPlainTextString } from '@pyra/contentful/utils/document-to-plain-text-string';
import { LogoTwitterX } from 'geist/new-icons/16';
import { exhaustivenessCheck } from '@pyra/error-utils';
import type { IconName } from 'geist/symbols';
import type { BlogAuthor, BlogTwitterUser } from '@/(blog)/types';
import type { BlogExploreCard, LinkedBlogPost } from '@/(blog)/types';
import { formatArticleDate } from '@/(blog)/lib/utils/format-article-date';
import { BlogAuthors } from '@/(blog)/components/author';
import { FrameworkLogo } from '../../../lib/utils/framework-logo';
import { filterNameToSlug } from '../../../lib/utils/filter-name-to-slug';
import styles from './explore-card-credit.module.css';

/*
   Note:
   Contentful cannot enforce/require certain fields based on the value of another:
      as a result, most of the explore card fields are optional in Contentful.
   So this file is kind of a pain because of this limitation.

   We rely on content authors to accurately set the explore card `type` and provide the necessary fields.
*/

export function ExploreCardCredit({
  credit,
}: {
  credit: Credit | undefined;
}): JSX.Element | null {
  if (!credit) {
    return null;
  }

  // @ts-expect-error — bad types
  const { customerLogo, authors, twitterUser, name, framework, icon, text } =
    credit;

  // customer story card
  if (customerLogo) {
    return <Logo balanced height={20} name={customerLogo as LogoName} />;
  }

  // blog post + fallback for customer story card
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (authors && authors.length > 0) {
    return <BlogAuthors authors={authors as BlogAuthor[]} />;
  }

  // landing page card
  if (text) {
    return (
      <div className={styles['landing-page']}>
        {icon ? (
          <Icon color="var(--ds-gray-1000)" name={icon as IconName} />
        ) : null}
        <Text variant="label-14" weight={500}>
          {text}
        </Text>
      </div>
    );
  }

  //  tweet card
  if (twitterUser) {
    return (
      <div className={styles.twitter}>
        <Image
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          alt={`${twitterUser.name}'s avatar`}
          height={18}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          src={twitterUser.image.url}
          width={18}
        />
        <Text as="span" variant="label-14" weight={500}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          {twitterUser.name}
        </Text>
        <Text as="span" color="gray-900" variant="label-14">
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
          @{twitterUser.username}
        </Text>
      </div>
    );
  }

  // template card
  if (name) {
    return (
      <div className={styles.template}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        {framework ? <FrameworkLogo height={16} value={framework} /> : null}
        <Text variant="label-14" weight={500}>
          {name}
        </Text>
      </div>
    );
  }

  return null;
}

export function parseExploreCardContent(card: BlogExploreCard): {
  href: string | undefined;
  title: string | undefined;
  credit: Credit;
  label: string | JSX.Element | undefined;
} | null {
  const kpi = getCustomerStoryKPI(card.customerStory);

  switch (card.type) {
    case 'tweet':
      return {
        href: card.tweetUrl,
        title: card.tweetText
          ? documentToPlainTextString(card.tweetText)
          : undefined,
        label: <LogoTwitterX aria-label="Twitter icon" />,
        credit: {
          twitterUser: card.twitterUser,
        },
      };

    case 'case-study':
      return {
        href: card.customerStory?.slug,
        title: card.customerStory?.title,
        label: kpi ? (
          <span>
            <strong>{kpi.title}</strong> {kpi.description}{' '}
          </span>
        ) : (
          getFormattedPostDate(card.customerStory?.date)
        ),
        credit: card.customerStoryLogo
          ? { customerLogo: card.customerStoryLogo.logo }
          : {
              authors: card.customerStory?.authors,
            },
      };

    case 'further-reading':
      return {
        href: card.furtherReading?.slug,
        title: card.furtherReading?.title,
        credit: {
          authors: card.furtherReading?.authors,
        },
        label: getFormattedPostDate(card.furtherReading?.date),
      };

    case 'landing-page':
      return {
        href: card.landingPageUrl,
        title: card.landingPageTitle,
        label: card.landingPageLabel,
        credit: {
          icon: card.landingPageIcon,
          text: card.landingPageActionText,
        },
      };

    case 'template': {
      const firstFramework = card.template?.framework?.[0];
      const templateHref =
        card.template?.slug && firstFramework
          ? `/templates/${filterNameToSlug(
              firstFramework,
            )}/${card.template.slug}`
          : undefined;

      return {
        href: templateHref,
        label: 'Deploy this template',
        title: card.template?.description ? card.template.description : '',
        credit: {
          name: card.template?.name,
          framework: firstFramework,
        },
      };
    }

    case undefined: {
      return null;
    }

    default:
      exhaustivenessCheck(card.type);
  }
}

function getCustomerStoryKPI(post?: LinkedBlogPost | undefined):
  | {
      title: string;
      description: string;
    }
  | undefined {
  const {
    kpi1ImprovementTitle,
    kpi1ImprovementDescription,
    kpi2ImprovementTitle,
    kpi2ImprovementDescription,
  } = post ?? {};

  if (kpi1ImprovementTitle && kpi1ImprovementDescription) {
    return {
      title: kpi1ImprovementTitle,
      description: kpi1ImprovementDescription,
    };
  }

  if (kpi2ImprovementTitle && kpi2ImprovementDescription) {
    return {
      title: kpi2ImprovementTitle,
      description: kpi2ImprovementDescription,
    };
  }

  return undefined;
}

function getFormattedPostDate(date: string | undefined): string | undefined {
  if (!date) return undefined;

  return formatArticleDate(date, true);
}

/*
 * Types
 */

interface CustomerStoryCredit {
  customerLogo: LogoName;
}

interface FurtherReadingCredit {
  authors: BlogAuthor[] | undefined;
}

interface TemplateCredit {
  name: string | undefined;
  framework: string | undefined;
}

type LandingPageCredit =
  | {
      icon: string | undefined;
      text: string | undefined;
    }
  | { description: string };

interface TweetCredit {
  twitterUser: BlogTwitterUser | undefined;
}

type Credit =
  | CustomerStoryCredit
  | FurtherReadingCredit
  | TemplateCredit
  | LandingPageCredit
  | TweetCredit;
